import React from 'react';
import 'normalize.css';
import { ChakraProvider, ColorModeProvider } from '@chakra-ui/react';
import styled from 'styled-components';
import { Footer } from './Footer';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import { Nav } from './Nav';

const OuterStyles = styled.div`
  min-height: 100vh;
  min-width: 100vw;
  overflow: hidden;
  display: grid;
  grid-template-rows: 60px 1fr auto;
  @media (max-width: 414px) {
    grid-template-rows: 50px 1fr auto;
  }
`;

const InnerStyles = styled.div`
  min-height: calc(100vh - 70px);
  padding-bottom: 1rem;
  width: 1000px;
  margin: 0 auto;
  overflow: hidden;
  @media (max-width: 1040px) {
    width: 800px;
  }
  @media (max-width: 840px) {
    width: 600px;
  }
  @media (max-width: 640px) {
    width: 95vw;
  }
  /* @media (max-width: 414px) {
    width: 333.334px;
  } */
`;

export default function Layout({ children }) {
  return (
    <ChakraProvider resetCSS>
      <ColorModeProvider
        options={{
          useSystemColorMode: true,
        }}
      >
        <OuterStyles>
          <GlobalStyles />
          <Typography />
          <div>
            <Nav />
          </div>
          <InnerStyles>{children}</InnerStyles>
          <Footer />
        </OuterStyles>
      </ColorModeProvider>
    </ChakraProvider>
  );
}
