// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-add-fact-js": () => import("./../../../src/pages/add-fact.js" /* webpackChunkName: "component---src-pages-add-fact-js" */),
  "component---src-pages-comedians-js": () => import("./../../../src/pages/comedians.js" /* webpackChunkName: "component---src-pages-comedians-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-create-post-js": () => import("./../../../src/pages/create-post.js" /* webpackChunkName: "component---src-pages-create-post-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-posts-js": () => import("./../../../src/pages/posts.js" /* webpackChunkName: "component---src-pages-posts-js" */),
  "component---src-pages-search-comedians-js": () => import("./../../../src/pages/search/comedians.js" /* webpackChunkName: "component---src-pages-search-comedians-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-search-posts-js": () => import("./../../../src/pages/search/posts.js" /* webpackChunkName: "component---src-pages-search-posts-js" */),
  "component---src-pages-views-js": () => import("./../../../src/pages/views.js" /* webpackChunkName: "component---src-pages-views-js" */),
  "component---src-templates-comedian-category-js": () => import("./../../../src/templates/ComedianCategory.js" /* webpackChunkName: "component---src-templates-comedian-category-js" */),
  "component---src-templates-comedian-js": () => import("./../../../src/templates/Comedian.js" /* webpackChunkName: "component---src-templates-comedian-js" */),
  "component---src-templates-post-category-js": () => import("./../../../src/templates/PostCategory.js" /* webpackChunkName: "component---src-templates-post-category-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/Post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

