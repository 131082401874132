/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable arrow-body-style */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/no-children-prop */
/* eslint-disable prettier/prettier */
import { Box, Grid, InputGroup, Input, InputRightElement, Text } from '@chakra-ui/react';
import { Link } from 'gatsby';
import { IoMenu } from 'react-icons/io5';
import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from '@reach/router';
import styled from 'styled-components';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { SearchIcon } from '@chakra-ui/icons';
import { slugify } from '../utils/slugify';

const NavStyles = styled.div`
  width: 100%;
  height: 60px;
  background: #15181d;
  padding: 0 2rem;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  color: white;
  @media (max-width: 414px) {
    height: 50px;
  }
  .nav-grid {
    position: relative;
    width: 1000px;
    display: grid;
    grid-template-columns: auto auto 1fr;
    align-items: center;
    grid-gap: 1rem;
    @media(max-width: 1040px) {
      width: 800px;
    }
    @media(max-width: 840px) {
        width: 600px;
        grid-template-columns: auto 1fr auto;
        grid-gap: 2rem;
    }
    @media(max-width: 640px) {
        margin-left: -0.8rem;
        width: calc(95vw - 10px);
        grid-template-columns: auto 1fr auto;
    }
    @media(max-width: 414px) {
      grid-template-columns: 1fr auto;
    }
  }
  .site-name {
    display: block;
    margin: 0;
    margin-right: 2.5rem;
    margin-bottom: -0.2rem;
    font-family: Chivo, 'sf_mono', --apple-system, BlinkMacSystemFont;
    &:hover {
      cursor: pointer;
      color: #8b9dc3;
    }
    p {
      font-size: 28px;
      font-weight: 300;
      letter-spacing: 1px;
    }
    @media(max-width: 414px) {
      display: none;
    }
  }
  .narrow {
    display: none;
    @media(max-width: 840px) {
      display: block;
      margin: 0;
      margin-left: -1rem;
    }
    @media(max-width: 414px) {
      display: none;
    }
  }
  .menu-icon-grid {
    @media(max-width: 414px) {
      display: none;
    }
  }
  .menu-button {
    display: block;
    font-size: 24px;
    color: #4056a1;
    &:hover {
      color: #8b9dc3;
    }
  }
  .wide {
    @media(max-width: 840px) {
      display: none;
    }
  }
  .links-flex {
    justify-self: end;
    display: flex;
    align-items: center;
    @media(max-width: 414px) {
      display: none;
    }
  }
  .link {
    display: inline-block;
    margin-left: 0.5rem;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: -6px;
    &:hover {
      cursor: pointer;
      color: #8b9dc3;
    }
    @media (max-width: 414px) {
      &:hover {
        color: white;
      }
    }
  }
  #show-links {
    justify-self: start;
    background: #15181d;
    padding: 0.8rem 0 2rem 0;
    position: absolute;
    left: -12px;
    top: 32px;
    width: 100vw;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;
    .link {
      margin-left: -3px;
      font-size: 15px;
      font-weight: 500;
    }
  }
  #home {
    display: none;
    @media (max-width: 414px) {
      display: inline-block;
    }
  }
  .avatar {
    border-radius: 50%;
    margin-left: 15px;
    width: 30px;
    height: 30px;
    &:hover {
      cursor: pointer;
    }
  }
  .search-wrapper {
    @media(max-width: 640px) {
        display: none;
    }
  }
  .search-input {
    background: white;
    padding: 15px 12px;
    font-size: 14px;
    color: black;
  }
  .search-icon {
    font-size: 14px;
    padding: 2px 2px 0 0;
    background: #4056a1;
    color: white;
    height: calc(100% + 1px);
    width: 34px;
    border-radius: 0 3px 3px 0;
    margin-top: -0.1px;
    &:hover {
      cursor: pointer;
    }
  }
  .search-icon-narrow {
    display: none;
    @media(max-width: 640px) {
        display: block;
        justify-self: end;
        font-size: 14px;
        margin-left: 0.7rem;
        margin-bottom: -0.1rem;
        &:hover {
          cursor: pointer;
        }
    }
    @media(max-width: 414px) {
      font-size: 16px;
    }
  }
  #show {
      width: 100%;
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      gap: 15px;
  }
  #search-icon-wrapper {
      display: none;
  }
  #show-search {
      grid-template-columns: 1fr;
      justify-items: center;
      margin-left: -15px;
      /* margin-top: -23px; */
      @media(max-width: 414px) {
        margin-left: -7px;
      }
      .menu-button, .site-name, .links-flex, .search-icon-narrow {
        display: none;
      }
  }
  a, button {
    margin-left: 2rem;
  }
  #home {
    margin-left: 0;
  }
  .hamburger-wrapper {
    display: none;
    @media (max-width: 414px) {
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      color: white;
      font-size: 22px;
      margin-left: 0.5rem;
      margin-bottom: -2px;
    }
  }
  #hide-hamburger {
    display: none;
  }
`;

export const Nav = () => {
  const [showLinks, setShowLinks] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    function handleClickOutside(event) {
        if (dropdownRef?.current && !dropdownRef?.current.contains(event.target)) {
            setShowLinks(false);
        }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, [dropdownRef, showLinks]);

  const searchTerm = '';
  const [search, setSearch] = useState(searchTerm || '');
  const searchRef = useRef(null);

  const isEnterPressed = (e) => {
    if (e.keyCode === 13) {
      if (!search.trim()) {
        return;
      }
      navigate(`/search/?s=${slugify(search)}`);
      if (searchRef?.current) {
        searchRef.current.blur();
      }
      setShowSearch(false);
    }
  };

  const handleSearchIconClick = () => {
    navigate(`/search/?s=${slugify(search)}`);
    if (searchRef?.current) {
      searchRef.current.blur();
    }
    setShowSearch(false);
  }

  return (
    <NavStyles>
      <Grid className="nav-grid" id={showSearch ? 'show-search' : ''}>
        <Link to="/" className="site-name">
          <Text>Yuks</Text>
        </Link>
        <Grid className="search-wrapper" id={showSearch ? 'show' : ''}>
            <Box display={showSearch ? 'block' : 'none' }>
                <AiOutlineArrowLeft fontSize="20px" cursor="pointer" onClick={() => setShowSearch(false)} />
            </Box>
            <InputGroup w={showSearch ? '100%' : '250px'}>
                <Input w={showSearch ? '100%' : '250px'} className="search-input" ref={searchRef} autoComplete="off" type="text" id="search" name="search" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} onKeyDown={(e) => isEnterPressed(e)}  />
                <InputRightElement className="search-icon" children={<SearchIcon />}
                  pointerEvents={search ? 'auto' : 'none'}
                  onClick={() => handleSearchIconClick()}
                />
            </InputGroup>
        </Grid>
        <Box pointerEvents={dropdownRef && showLinks ? 'none' : 'auto'} className="hamburger-wrapper" id={showSearch ? 'hide-hamburger' : ''}>
          <IoMenu onClick={() => setShowLinks(true)} />
        </Box>
        <div ref={dropdownRef} className="links-flex" id={showLinks ? 'show-links' : ''}>
          <Link to="/"><span id="home" className="link" onClick={() => setShowLinks(false)}>Home</span></Link>
          <Link to="/comedians"><span id="comedians" className="link" onClick={() => setShowLinks(false)}>Comedians</span></Link>
          <Link to="/posts"><span id="advice" className="link" onClick={() => setShowLinks(false)}>Posts</span></Link>
        </div>
        <SearchIcon onClick={() => setShowSearch(true)} className="search-icon-narrow" />
      </Grid>
    </NavStyles>
  )
};
