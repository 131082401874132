import { createGlobalStyle } from 'styled-components';
import font from '../../static/Chivo-Bold.woff';

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'sf_mono';
    src: url('static/SFMonoRegular.woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: Chivo;
    src: url(${font});
  }
  :root {
    --white: #ecedf1;
    --black: #0a0f19;
    --lightBlue: #9bc2db;
    --mainBlue: #327ea6;
    --darkBlue: #17496a;
    --darkestBlue: #18213d;
    --blue: #0745ad;
    --facebook: #4056a1;
    --seaGreen: #52a34d;
    --red: #ff0000;
    --grey: #3A3A3A;
    --gray: var(--grey);
    --offWhite: #ededed;
    --maxWidth: 1000px;
    --bs: 0 12px 24px 0 rgba(0,0,0,0.09);
    box-sizing: border-box;
    font-size: 62.5%;
  }
  html {
    font-family: 'sf_mono';
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    font-size: 1.5rem;
    line-height: 1.25;
    color: var(--black);
    background: var(--white);
    font-family: 'sf_mono', --apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding: 0;
    margin: 0;
  }

  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }
  
  body::-webkit-scrollbar {
    display: none;
  }

  img {
    max-width: 100%;
    filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, .3));
  }

  .avatar-wrapper {
    filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.3));
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

`;

export default GlobalStyles;