/* eslint-disable arrow-body-style */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/no-children-prop */
/* eslint-disable prettier/prettier */
import { Flex, Grid, Text } from '@chakra-ui/react';
import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const FooterStyles = styled.div`
  width: 100%;
  height: 150px;
  background: #15181d;
  color: white;
  padding-top: 3rem;
  .footer-grid {
    width: 1000px;
    margin: 0 auto;
    @media(max-width: 1040px) {
      width: 800px;
    }
    @media(max-width: 840px) {
        width: 600px;
    }
    @media(max-width: 640px) {
        width: calc(95vw - 20px);
    }
  }
  .footer-flex {
    align-items: center;
  }
  p {
    line-height: 1.25;
  }
  .link {
    font-weight: 700;
    color: #99aabb;
    &:hover {
        color: #667788;
    }
  }
  .margin {
    margin-left: 2rem;
  }
  .copyright {
    font-size: 12px;
    margin-top: 1.5rem;
    color: #667788;
  }

`;

export const Footer = () => {
  return (
    <FooterStyles>
        <Grid className="footer-grid">
            <Flex className="footer-flex">
                <Link href="/about"><Text margin="0" className="link">About</Text></Link>
                <Link href="/contact"><Text className="link margin">Contact</Text></Link>
            </Flex>
            <Text className="copyright">© Yuks. <span>Made by a comedy fan in Philadelphia, PA.</span></Text>
        </Grid>
    </FooterStyles>
  )
};
